<script>
import _ from "lodash";

const List = () =>
  import(/* webpackChunkName: "task-list-page" */ "./pages/list");
const CreateOrEdit = () =>
  import(
    /* webpackChunkName: "task-create-edit-page" */ "./pages/createOrEdit"
  );
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";
import { currentTime } from "@/utils/time";

export default {
  name: "TasksIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("tasks", {
      contents: state => state.tasks,
      taskFilter: state => state.taskFilter
    }),

    ...mapState("app", {
      enabledFeatures: state => state.enabled_features,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    /**
     * Get the feature key for the current task filter
     */
    taskPermissionKey() {
      return this.taskFilterToFeatureKey(this.taskFilter);
    }
  },

  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages",
      setTaskFilter: "setTaskFilter"
    }),

    ...mapActions("variables", {
      fetchVariables: "forceFetchVariables"
    }),

    ...mapActions("canvas", {
      setReadOnlyAccess: "setReadOnlyAccess"
    }),

    initContent() {
      let now = currentTime();
      return {
        task_name: "",
        task_type: this.taskFilter,
        tags: "",
        task_image: "",
        is_favorite: 0,
        is_system_log_shown: false,
        debug_logs_enabled: false,
        logging_profiles: {
          data: []
        },
        voice_task: {
          data: {
            tts_voice_id: "",
            beep_detection: false,
            machine_detection: false,
            send_to_operator: false,
            no_resource_operator_number: ""
          }
        },
        workflow_task: {
          data: {
            is_active: true,
            workflow_schedule: "Once",
            schedule_time_zone: "",
            start_on: now,
            is_repeat: 0,
            repeat_frequency: "01:00:00",
            number_of_executions: 0,
            passed_params: {
              data: []
            },
            schedule: {
              data: {
                starts_on: now,
                repeat_frequency: "Daily",
                repeat_interval: 1,
                repeat_in: "",
                end_type: "Never",
                end_type_value: "",
                repeat_summary: ""
              }
            }
          }
        },
        messaging_task: {
          data: {
            msg_session_timeout: 60
          }
        },
        chatbot_task: {
          data: {
            api_key: "",
            chatbot_session_timeout: 60
          }
        },
        whatsapp_task: {
          data: {
            msg_session_timeout: 60
          }
        }
      };
    },

    /**
     * Convert task filter key to feature key
     * @param { String } filter Task filter
     * @throws { TypeError } When filter is not a valid task filter
     * @returns { String } When filter is null or undefined, an empty string is returned
     */
    taskFilterToFeatureKey(filter) {
      if (filter === null || filter === undefined) {
        return "";
      }

      const filterLowerCase = filter.toLowerCase();
      const map = {
        voice: "Voice Task",
        messaging: "Messaging",
        workflow: "Workflow",
        chatbot: "Chatbot",
        whatsapp: "Whatsapp"
      };

      if (!map[filterLowerCase]) {
        throw new TypeError(`"${filter}" is not a valid task filter`);
      }

      return map[filterLowerCase];
    },

    assignTaskFilter() {
      if (
        [
          "biometric",
          "transcription",
          "toneanalyzer",
          "numberinsight"
        ].includes(this.taskFilter)
      ) {
        this.changeTaskFilter();
      }
      if (!this.taskFilter || !this.can(this.taskPermissionKey)) {
        this.changeTaskFilter();
      }
    },
    changeTaskFilter() {
      switch (true) {
        case this.can("Voice Task"):
          this.setTaskFilter("voice");
          break;
        case this.can("Messaging"):
          this.setTaskFilter("messaging");
          break;
        case this.can("Workflow"):
          this.setTaskFilter("workflow");
          break;
        case this.can("Chatbot"):
          this.setTaskFilter("chatbot");
          break;
        case this.can("Whatsapp"):
          this.setTaskFilter("whatsapp");
          break;
        default:
          this.setTaskFilter(null);
      }
    }
  },
  watch: {
    enabledFeatures: {
      handler(newVal) {
        // Only change task filter if the enabled features no longer contain the currently
        // selected task filter. This should prevent something similar
        // to https://five9inc.atlassian.net/browse/STUD-5137 from happening
        if (newVal.indexOf(this.taskPermissionKey) === -1) {
          this.changeTaskFilter();
        }
      }
    }
  },

  created() {
    let scope = "";

    if (this.selectedAccountId !== "all") {
      scope = "ac";
    } else if (this.selectedServiceProviderId !== "all") {
      scope = "sp";
    }

    if (scope !== "sp") {
      this.getLanguages();
      this.fetchVariables().catch(err => {
        if (err.response.status === 403) {
          let requestUrl = _.get(err, "response.config.url", "");
          let requestContent =
            _.last(requestUrl.split("?")[0].split("/")) || "dependency";
          this.$message.error(
            // eslint-disable-next-line
            __("You do not have the required permissions required to view task details. Please contact your system administrator to assign :requestContent permissions  to view the task.", {
                requestContent: requestContent.toUpperCase()
              }
            )
          );
        }
      });
    }

    this.assignTaskFilter();
    this.contentType = "Task";
    this.primaryKey = "task_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
